import React, { useState, useEffect, useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import Modal from "react-bootstrap/Modal"
import { AnchorComponent, ButtonComponent } from "../elements"
import _ from "lodash"
import { MdEdit } from "react-icons/md"

export default function RulesTable({ thead, tbody, setDeleteMaster }) {
  const { t } = useContext(TranslatorContext)

  const [alertModal, setAlertModal] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setData(tbody)
  }, [tbody])

  const handleCheckbox = (event) => {
    const { name, checked } = event.target

    if (name === "allCheck") {
      const checkData = data?.map((item) => {
        return { ...item, isChecked: checked }
      })
      setData(checkData)
    } else {
      const checkData = data?.map((item) =>
        item.name === name ? { ...item, isChecked: checked } : item
      )
      setData(checkData)
    }
  }

  return (
    <div className="mc-table-responsive">
      <table className="mc-table product">
        <thead className="mc-table-head primary">
          <tr>
            {thead.map((item, index) => (
              <th key={index}>{t(item)}</th>
            ))}
          </tr>
        </thead>

        <tbody className="mc-table-body even">
          {data?.map((item, index) => (
            <tr key={item?.id}>
              <td>{item.id}</td>

              <td>{item.login}</td>

              <td>{_.map(item?.slaves, (_s) => _s?.login).join(",")}</td>

              <td>{item.isActive == 1 ? "Active" : "Inactive"}</td>

              <td>
                <AnchorComponent
                  to={`/rules/edit/${item?.login}`}
                  title="Edit"
                  className="material-icons edit"
                >
                  <MdEdit color="blue" size="16px" cursor="pointer" />
                </AnchorComponent>
                &nbsp; &nbsp;
                <ButtonComponent
                  type="button"
                  title="Delete"
                  className="material-icons delete"
                  onClick={() => setDeleteMaster(item)}
                  size="sm"
                  color="red"
                  style={{
                    color: "red",
                  }}
                >
                  delete
                </ButtonComponent>
                {/* <div className="mc-table-action">
                  <AnchorComponent
                    // to="/product-view"
                    title="View"
                    className="material-icons view"
                  >
                    {item.action.view}
                  </AnchorComponent>
                 
                    {item.action.edit}
                  </AnchorComponent>
                  <ButtonComponent
                    type="button"
                    title="Delete"
                    className="material-icons delete"
                    onClick={() => setAlertModal(true)}
                  >
                 
                  </ButtonComponent>
                </div> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
