import axios from "axios";
import axiosRetry from "axios-retry";
import { APP } from "../config";
import _ from "lodash";
import { checkTokenExpiry } from "../utils/tokenUtils";
import { redirectToLogin } from "../utils/redirectToLogin";

const api = axios.create({ baseURL: APP.BASE_API_URL });

axiosRetry(api, { retries: 3 });

api.interceptors.request.use(
  (config) => {
    const token = checkTokenExpiry();
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      checkTokenExpiry();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error.response.status) {
      // localStorage.removeItem(STORAGE.USER)
      redirectToLogin();
      return Promise.reject(error);
    }
  }
);

export default api;
