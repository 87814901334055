import React, { useContext, useEffect, useState } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Link, json, useParams, useSearchParams } from "react-router-dom"
import { Row, Col, Form, Spinner } from "react-bootstrap"
import { ButtonComponent } from "../../components/elements"
import {
  LabelFieldComponent,
  LabelTextareaComponent,
} from "../../components/fields"
import PageLayout from "../../layouts/PageLayout"
import { useQuery } from "@tanstack/react-query"
import {
  createSlaveService,
  getDealsService,
  getSingleMasterView,
  updateMasterService,
  updateSlaveService,
  deleteSlaveService,
} from "../../services/main"
import { Loader } from "../../context/Loader"
import _ from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Modal } from "react-bootstrap"
import { MdDelete, MdEdit } from "react-icons/md"
import toast from "react-hot-toast"
import UploadCsvModal from "../../components/pages/RulesComponents/UploadCsvModal"

const d = {
  thead: ["id", "login", "status", "action"],
}

export default function RulesEditPage() {
  const [rule, setRule] = useState(undefined)
  const [slaves, setSlaves] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()
  const [isSlaveModalOpen, SetIsSlaveModalOpen] = useState(false)
  const [slaveToEdit, setSlaveToEdit] = useState(undefined)
  const [deleteSlave, setDeleteSlave] = useState(undefined)
  const [isUploadCsvModal, setIsUploadCsvModal] = useState(false)
  const [isRemovedSlavesModalOpen, setIsRemovedSlavesModalOpen] =
    useState(false)

  useEffect(() => {
    refetch()
  }, [isUploadCsvModal])

  const refetch = async () => {
    setIsLoading(true)
    try {
      const result = await getSingleMasterView(id)

      if (result?.data?.status) {
        setSlaves(result?.data?.data?.slaves)
        if (result?.data?.data?.masters?.length) {
          setRule(result?.data?.data?.masters[0])
        }
      } else {
      }
    } catch (error) {}
    setIsLoading(false)
  }

  const onRemovedSlaveModalClick = () => {
    setIsUploadCsvModal(true)
    setIsRemovedSlavesModalOpen(true)
  }

  console.log(slaves?.length)

  if (isLoading) <Loader />

  return (
    <PageLayout>
      <div className="mc-card">
        <div className="mc-breadcrumb">
          <h3 className="mc-breadcrumb-title">Edit Rules</h3>
          <ul className="mc-breadcrumb-list">
            <li className="mc-breadcrumb-item">
              <Link to="/" className="mc-breadcrumb-link">
                Home
              </Link>
            </li>
            <li className="mc-breadcrumb-item">
              <Link to="/rules" className="mc-breadcrumb-link">
                Rules
              </Link>
            </li>
            <li className="mc-breadcrumb-item">Edit Rule</li>
          </ul>
        </div>
      </div>
      <div className="mc-card p-sm-5">
        <div className="mb-5">
          <h6 className="mc-divide-title mb-4">Edit Tules</h6>
          <Row>
            <Col xl={4}>
              <p className="mc-setting-descrip">Details</p>
            </Col>
            <Col xl={8} className="ps-xl-5">
              {rule ? (
                <MasterFormEditTable rule={rule} setRule={setRule} />
              ) : null}
            </Col>
          </Row>
        </div>
        <div className="mb-5">
          <h6 className="mc-divide-title mb-4">Slaves</h6>
          <Row>
            <Col xl={4}></Col>
            <Col xl={8} className="ps-xl-5">
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <ButtonComponent
                    size="sm"
                    className="mc-btn primary"
                    text={"Add Slaves Via File"}
                    onClick={() => {
                      setIsUploadCsvModal(true)
                    }}
                  />
                  &emsp;
                  {slaves?.length > 1 && (
                    <ButtonComponent
                      size="sm"
                      className="mc-btn red"
                      text={"Remove Slaves Via File"}
                      onClick={onRemovedSlaveModalClick}
                    />
                  )}
                </div>
                <ButtonComponent
                  size="sm"
                  className="mc-btn primary"
                  text={"Add New Slave"}
                  onClick={() => {
                    SetIsSlaveModalOpen(true)
                  }}
                />
              </div>
              <br />
              <SlavesTable
                slaves={slaves}
                slaveToEdit={slaveToEdit}
                setSlaveToEdit={(_s) => {
                  setSlaveToEdit(_s)
                  SetIsSlaveModalOpen(true)
                }}
                setDeleteSlave={setDeleteSlave}
              />
            </Col>
          </Row>
        </div>
      </div>
      <AddEditSlave
        isOpen={isSlaveModalOpen}
        setIsOpen={SetIsSlaveModalOpen}
        slaveToEdit={slaveToEdit}
        setSlaveToEdit={setSlaveToEdit}
        setSlaves={setSlaves}
        master={rule}
      />
      <DeleteSlaveModal
        deleteSlave={deleteSlave}
        setDeleteSlave={setDeleteSlave}
        refetch={refetch}
      />
      <UploadCsvModal
        isUploadCsvModal={isUploadCsvModal}
        setIsUploadCsvModal={setIsUploadCsvModal}
        isRemovedSlavesModalOpen={isRemovedSlavesModalOpen}
        setIsRemovedSlavesModalOpen={setIsRemovedSlavesModalOpen}
      />
    </PageLayout>
  )
}

const masterFormEditSchema = Yup.object().shape({
  login: Yup.number().required("Required"),
  isActive: Yup.boolean().required("Required"),
})

const MasterFormEditTable = ({ rule, setRule }) => {
  const formik = useFormik({
    initialValues: {
      login: rule?.login,
      isActive: rule?.isActive ?? false,
    },
    validationSchema: masterFormEditSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true)

      try {
        const result = await updateMasterService(
          rule?.id,
          values?.login,
          values?.isActive ? 1 : 0
        )

        if (result?.data?.status) {
          toast.success("Master updated successfully")
          setRule((_r) => {
            return {
              ..._r,
              login: values?.login,
              isActive: values?.isActive,
            }
          })
          //window.location.href = "/rules/edit/" + values?.login
        } else {
          toast.error(result?.data?.message ?? "Something went wrong`")
        }
      } catch (error) {}

      actions.setSubmitting(false)
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <LabelFieldComponent
              label={"Login"}
              type="number"
              fieldSize="w-100 h-md"
              name="login"
              value={formik?.values?.login}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              errorMessage={
                formik?.errors?.login && formik?.touched?.login
                  ? formik?.errors?.login
                  : false
              }
              disabled={true}
              readOnly={true}
            />
          </Col>

          <Col xl={12} className="mt-4">
            <Form.Check
              type="checkbox"
              id="checkbox4"
              className="mb-3"
              label="Is Active"
              checked={formik?.values?.isActive}
              onClick={() => {
                formik.setFieldValue("isActive", !formik?.values?.isActive)
              }}
            />
          </Col>
          <Col xl={8}>
            <ButtonComponent
              type="submit"
              disabled={formik?.isSubmitting}
              className="mc-btn primary"
            >
              {formik?.isSubmitting ? <Spinner size="sm" /> : "Save"}
            </ButtonComponent>
          </Col>
        </Row>
      </form>
    </>
  )
}

const SlavesTable = ({
  slaves,
  slaveToEdit,
  setSlaveToEdit,
  setDeleteSlave,
}) => {
  return (
    <>
      <div className="mc-table-responsive">
        <table className="mc-table product">
          <thead className="mc-table-head primary">
            <tr>
              {d?.thead.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody className="mc-table-body even">
            {slaves?.length ? (
              <>
                {_.map(slaves, (_s) => (
                  <tr key={_s?.id}>
                    <td> {_s?.id}</td>
                    <td> {_s?.login}</td>
                    <td> {_s?.isActive === 1 ? "Active" : "Inactive"}</td>
                    <td>
                      <ButtonComponent
                        onClick={() => {
                          setSlaveToEdit(_s)
                        }}
                        style={{ color: "blue" }}
                      >
                        <MdEdit />
                      </ButtonComponent>
                      &nbsp; &nbsp;
                      <ButtonComponent
                        onClick={() => {
                          setDeleteSlave(_s)
                        }}
                        style={{
                          color: "red",
                          fontSize: "18px",
                        }}
                      >
                        <MdDelete />
                      </ButtonComponent>
                      &nbsp;
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={d?.thead?.length} style={{ textAlign: "center" }}>
                  No Slaves found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

const AddEditSlave = ({
  isOpen,
  setIsOpen,
  slaveToEdit,
  setSlaves,
  setSlaveToEdit,
  master,
}) => {
  const formik = useFormik({
    initialValues: {
      login: slaveToEdit?.login ?? undefined,
      isActive: slaveToEdit?.isActive ?? true,
    },
    enableReinitialize: true,
    validationSchema: masterFormEditSchema,
    onSubmit: async (values, actions) => {
      try {
        if (slaveToEdit) {
          const result = await updateSlaveService(
            slaveToEdit?.id,
            values?.login,
            master?.login,
            values?.isActive === true ? 1 : 0
          )

          if (result?.data?.status) {
            toast.success("Slave updated succesfully")
            setSlaves((_s) =>
              _.map(_s, (s) => {
                return s?.id === slaveToEdit?.id
                  ? {
                      ...s,
                      login: values?.login,
                      isActive: values?.isActive === true ? 1 : 0,
                    }
                  : s
              })
            )
            setIsOpen(false)
            formik.resetForm()
          } else {
            toast.error(result?.data?.message ?? "Something went wrong")
          }
        } else {
          const result = await createSlaveService(values?.login, master?.login)

          if (result?.data?.status) {
            toast.success("Slave created succesfully")
            setSlaves((_s) => [result?.data?.data?.slave, ..._s])
            setIsOpen(false)
            formik.resetForm()
          } else {
            toast.error(result?.data?.message ?? "Something went wrong")
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
  })

  return (
    <>
      <Modal centered show={isOpen} onHide={() => setIsOpen(false)}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mc-alert-modal">
            {/* <i className="material-icons">new_releases</i> */}
            <br />
            <br />
            <h3>Add Slave Account!</h3>
            <br />

            <LabelFieldComponent
              style={{ width: "100%" }}
              label={"Login"}
              type="number"
              fieldSize="w-100 h-md"
              name="login"
              value={formik?.values?.login}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              errorMessage={
                formik?.errors?.login && formik?.touched?.login
                  ? formik?.errors?.login
                  : false
              }
            />

            <Col xl={12} className="mt-4" style={{ textAlign: "left" }}>
              <Form.Check
                type="checkbox"
                id="checkbox4"
                className="mb-3"
                label="Is Active"
                checked={formik?.values?.isActive}
                onClick={() => {
                  formik.setFieldValue("isActive", !formik?.values?.isActive)
                }}
              />
            </Col>

            <Modal.Footer>
              <ButtonComponent
                type="button"
                className="btn btn-secondary"
                onClick={() => setIsOpen(false)}
              >
                close
              </ButtonComponent>
              <ButtonComponent className="btn btn-danger" type="submit">
                {formik?.isSubmitting ? (
                  <Spinner size="sm" />
                ) : slaveToEdit ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </ButtonComponent>
            </Modal.Footer>
          </div>
        </form>
      </Modal>
    </>
  )
}

const DeleteSlaveModal = ({ deleteSlave, setDeleteSlave, refetch }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDeleteSlave = async () => {
    setIsDeleting(true)

    try {
      const result = await deleteSlaveService(deleteSlave?.id)
      console.log(result)
      if (result?.data?.status) {
        await refetch()
        toast?.success("Slave deleted successfully")
        setDeleteSlave(undefined)
      } else {
        toast?.error("Error in deleting slave")
      }
    } catch (error) {
      console.log(error)
    }

    setIsDeleting(false)
  }

  return (
    <>
      <Modal
        centered
        show={deleteSlave ? true : false}
        onHide={() => setDeleteSlave(false)}
      >
        <div className="mc-alert-modal">
          <i className="material-icons">new_releases</i>
          <h3>are your sure!</h3>
          <p>Want to delete this slave?</p>
          <Modal.Footer>
            <ButtonComponent
              type="button"
              className="btn btn-secondary"
              onClick={() => setDeleteSlave(false)}
              disabled={isDeleting}
            >
              close
            </ButtonComponent>
            <ButtonComponent
              type="button"
              className="btn btn-danger"
              onClick={() => {
                handleDeleteSlave()
              }}
              disabled={isDeleting}
              size="sm"
            >
              {isDeleting ? <Spinner size="sm" /> : "Delete"}
            </ButtonComponent>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}
