import React, { useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Dropdown } from "react-bootstrap"

export default function EcommerceCardComponent({
  variant,
  trend,
  number,
  title,
  icon,
  percent,
}) {
  const { t } = useContext(TranslatorContext)

  return (
    <div className={`mc-ecommerce-card ${variant}`}>
      <i className="mc-ecommerce-card-trend material-icons">{trend}</i>
      <div className="mc-ecommerce-card-head">
        <h4 className="mc-ecommerce-card-meta">
          <span>{title}</span>
          {number}
        </h4>
        <i className="mc-ecommerce-card-icon material-icons">{icon}</i>
      </div>
      <div className="mc-ecommerce-card-foot"></div>
    </div>
  )
}
