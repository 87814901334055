import { PulseLoader } from "react-spinners"

export const Loader = () => {
  return (
    <>
      <div className="mc-spinner">
        <img className="pattern" src="images/pattern.webp" alt="pattern" />
        <img className="favicon" src="images/logo.webp" alt="logo" />
        <div className="mc-spinner-group">
          <h3>Loading</h3>
          <PulseLoader color="#0857f5" loading={true} size={8} />
        </div>
      </div>
    </>
  )
}
