import React, { useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Row, Col } from "react-bootstrap"
import { BreadcrumbComponent, PaginationComponent } from "../../components"
import { CardHeaderComponent } from "../../components/cards"
import { AnchorComponent } from "../../components/elements"
import {
  ProductsTableComponent,
  UsersTableComponent,
  PagesTableComponent,
  TrafficsTableComponent,
} from "../../components/tables"
import PageLayout from "../../layouts/PageLayout"
import data from "../../assets/data/ui/tables.json"
import MappingsTable from "../../components/tables/MappingsTable"
import DealsTable from "../../components/tables/DealsTable"
import { getDealsService } from "../../services/main"
import { useQuery } from "@tanstack/react-query"
import { Loader } from "../../context/Loader"
import { constant } from "lodash"

const d = {
  thead: [
    "Deal ID",
    "Login",
    "volume",
    "price",
    "Symbol",
    "action",
    "entry",
    "Order ID",
    "profit",
    "time",
  ],
  tbody: [
    {
      positon: "195290",
      order: "249315",
      deal: "198720",
      login: "10023",
      symbol: "EURUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      action: "BUY",
      entry: "IN",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      positon: "195290",
      order: "249315",
      deal: "198720",
      login: "10023",
      symbol: "EURUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      actionVal: "SELL",
      entry: "OUT",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      positon: "295290",
      order: "349315",
      deal: "298720",
      login: "10026",
      symbol: "GBPUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      actionVal: "SELL",
      entry: "IN",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
  ],
}
export default function DealsPage() {
  const { t } = useContext(TranslatorContext)

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["deals"],
    queryFn: () => getDealsService(),
  })

  const deals = data?.data?.data?.deals ?? []

  if (isLoading) <Loader />

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <BreadcrumbComponent title={"Deals"}>
            <li className="mc-breadcrumb-item">Home</li>
            <li className="mc-breadcrumb-item">Deals</li>
          </BreadcrumbComponent>
        </Col>
        <Col xl={12}>
          <div className="mc-card">
            <DealsTable thead={d.thead} tbody={deals} />

            {/* <PaginationComponent /> */}
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}
