import React from "react"
import HeaderLayout from "./HeaderLayout"
import SidebarLayout from "./SidebarLayout"
import MainLayout from "./MainLayout"
import FooterLayout from "./FooterLayout"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import { STORAGE } from "../config"
import { redirectToLogin } from "../utils/redirectToLogin"

export default function PageLayout({ children }) {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const handleLogout = () => {
    console.log('handleLogout')
    localStorage.removeItem(STORAGE?.USER)
    redirectToLogin()
  }

  return (
    <>
      <HeaderLayout handleLogout={handleLogout}/>
      <SidebarLayout handleLogout={handleLogout}/>
      <MainLayout>
        <>
          {children}
          {pathname !== "/message" ? <FooterLayout /> : ""}
        </>
      </MainLayout>
    </>
  )
}
