import { redirectToLogin } from "./redirectToLogin";
import { checkTokenExpiry } from "./tokenUtils";

export const withAuth = (Component) => {
  return function WithAuth(props) {
    const token = checkTokenExpiry();
    if (!token) {
      redirectToLogin();
      return null;
    }
    return <Component {...props} />;
  };
};
