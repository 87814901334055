import React, { useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Dropdown } from "react-bootstrap"
import { OrdersChartComponent } from "../charts"

export default function OrdersCardComponent({ managers }) {
  const { t, n } = useContext(TranslatorContext)

  return (
    <div className="mc-card">
      <div className="mc-card-header">
        <h4 className="mc-card-title">{t("Top Money Managers")}</h4>
      </div>

      <ul className="mc-order-card-list">
        {!managers?.length ? (
          <li className="mc-order-card-item">No data found</li>
        ) : (
          <>
            {managers.map((order, index) => (
              <li key={index} className="mc-order-card-item">
                <p>{order?.Login}</p>
                <h5>{order.totalProfit}</h5>
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  )
}
