import { toast } from "react-toastify";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function emitNotification(variant, msg, autoClose = 2000) {
  const validVariants = ["success", "error", "info", "warning"];

  const toastVariant = validVariants.includes(variant) ? variant : "info";

  toast[toastVariant](msg, {
    containerId: "solid",
    autoClose,
    position: "top-right",
  });
}

export function emitErrorNotification(
  msg = "Something went wrong, Please try again!",
  autoClose = 2000
) {
  toast.error(msg, { containerId: "solid", autoClose, position: "top-right" });
}

export function getErrorMessage(error) {
  let errorMessage = "Failed to do something exceptional";
  if (error) {
    errorMessage = Array.isArray(error?.response?.data?.message)
      ? error?.response?.data?.message?.[0]
      : error?.response?.data?.message;
  }
  return errorMessage || "Something went wrong";
}
