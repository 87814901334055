import React, { useContext, useState, useRef } from "react"

// CONTEXT
import { ThemeContext } from "../context/Themes"
import { SidebarContext } from "../context/Sidebar"
import { TranslatorContext } from "../context/Translator"

// COMPONENTS
import { Link } from "react-router-dom"
import { Dropdown } from "react-bootstrap"

// DATA
import orders from "../assets/data/orders.json"
import messages from "../assets/data/messages.json"
import languages from "../assets/data/languages.json"
import notifications from "../assets/data/notifications.json"

export default function HeaderLayout({ handleLogout }) {
  const { theme, toggleTheme } = useContext(ThemeContext)
  const { sidebar, toggleSidebar } = useContext(SidebarContext)
  const { t, n, c, changeLanguage, currentLanguage } =
    useContext(TranslatorContext)

  const searchRef = useRef()

  const [scroll, setScroll] = useState("fixed")
  const [search, setSearch] = useState("")

  window.addEventListener("scroll", () => {
    if (window.scrollY > 0) setScroll("sticky")
    else setScroll("fixed")
  })

  document.addEventListener("mousedown", (event) => {
    if (!searchRef.current?.contains(event.target)) {
      setSearch("")
    }
  })

  return (
    <header className={`mc-header ${scroll}`}>
      <Link to="/" className="mc-logo-group">
        {/* <img src="/images/logo.webp" alt="logo" /> */}
        <span>MAMM</span>
      </Link>
      <div className="mc-header-group">
        <div className="mc-header-left">
          <button
            type="button"
            className="mc-header-icon search"
            onClick={() => setSearch("show")}
          >
            <i className="material-icons">search</i>
          </button>
          <button
            type="button"
            className="mc-header-icon toggle"
            onClick={toggleSidebar}
          >
            <i className="material-icons">{sidebar ? "menu_open" : "menu"}</i>
          </button>
          <div className={`mc-header-search-group ${search}`}>
            {/* <form className="mc-header-search" ref={searchRef}>
              <button type="button" className="material-icons">
                search
              </button>
              <input type="search" placeholder={t("quick_finding") + "..."} />
            </form> */}
          </div>
        </div>

        <div className="mc-header-right">
          <button
            type="button"
            className="mc-header-icon theme"
            onClick={toggleTheme}
            style={{ display: "none" }}
          >
            <i className="material-icons">{theme}</i>
          </button>

          <Dropdown className="mc-header-user">
            <Dropdown.Toggle className="mc-dropdown-toggle">
              <div className="mc-duel-text xs">
                <h3 className="mc-duel-text-title">admin</h3>
                <p className="mc-duel-text-descrip">admin@pamm.com</p>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="mc-dropdown-paper">
              <Link to="/" className="mc-dropdown-menu">
                <i className="material-icons">person</i>
                <span>{t("my_account")}</span>
              </Link>
              <Link to="/" className="mc-dropdown-menu">
                <i className="material-icons">privacy_tip</i>
                <span>{t("reset_password")}</span>
              </Link>
              <Link onClick={handleLogout} className="mc-dropdown-menu">
                <i className="material-icons">lock</i>
                <span>{t("logout")}</span>
              </Link>
            </Dropdown.Menu>
          </Dropdown>
          {/*================================
                            PROFILE PART END
                    ================================*/}
        </div>
      </div>
    </header>
  )
}
