import React, { useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Row, Col } from "react-bootstrap"
import { BreadcrumbComponent, PaginationComponent } from "../../components"
import { CardHeaderComponent } from "../../components/cards"
import { AnchorComponent } from "../../components/elements"
import {
  ProductsTableComponent,
  UsersTableComponent,
  PagesTableComponent,
  TrafficsTableComponent,
} from "../../components/tables"
import PageLayout from "../../layouts/PageLayout"
import data from "../../assets/data/ui/tables.json"
import MappingsTable from "../../components/tables/MappingsTable"

const d = {
  thead: ["master", "slave", "action"],
  tbody: [
    {
      master: "XAUD_USD",
      slave: "XAUDUSD",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      master: "EUR_USD",
      slave: "EURUSD",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      master: "GBP/USD",
      slave: "GBP_USD",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
  ],
}

export default function MappingsPage() {
  const { t } = useContext(TranslatorContext)
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <BreadcrumbComponent title={"Symbol Mappings"}>
            <li className="mc-breadcrumb-item">Home</li>
            <li className="mc-breadcrumb-item">Symbol Mappings</li>
          </BreadcrumbComponent>
        </Col>
        <Col xl={12}>
          <div className="mc-card">
            {/* <CardHeaderComponent
              title={"Symbol Mappings"}
              dotsMenu={data?.products.dotsMenu}
            /> */}
            <MappingsTable thead={d.thead} tbody={d.tbody} />

            <PaginationComponent />
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}
