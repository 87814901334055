import React, { useContext, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Link } from "react-router-dom";
import { Row, Col, Spinner } from "react-bootstrap";
import {
  EcommerceCardComponent,
  SalesCardComponent,
  ProductsCardComponent,
  RevenueCardComponent,
  ClientsCardComponent,
  ActivityCardComponent,
  OrdersCardComponent,
  CardHeaderComponent,
} from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import heros from "../../assets/data/heros.json";
import DealsTable from "../../components/tables/DealsTable";
import { copyDealService, getDashboardService } from "../../services/main";
import { Loader } from "../../context/Loader";
import { useQuery } from "@tanstack/react-query";
import { LabelFieldComponent } from "../../components/fields";
import { ButtonComponent } from "../../components/elements";
import toast, { Toaster } from "react-hot-toast";

const d = {
  thead: [
    "Deal ID",
    "Login",
    "volume",
    "price",
    "Symbol",
    "action",
    "entry",
    "Order ID",
    "profit",
    "time",
  ],
  tbody: [
    {
      positon: "195290",
      order: "249315",
      deal: "198720",
      login: "10023",
      symbol: "EURUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      action: "BUY",
      entry: "IN",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      positon: "195290",
      order: "249315",
      deal: "198720",
      login: "10023",
      symbol: "EURUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      actionVal: "SELL",
      entry: "OUT",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      positon: "295290",
      order: "349315",
      deal: "298720",
      login: "10026",
      symbol: "GBPUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      actionVal: "SELL",
      entry: "IN",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      positon: "195290",
      order: "249315",
      deal: "198720",
      login: "10023",
      symbol: "EURUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      action: "BUY",
      entry: "IN",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
  ],
};

export default function CopyDealPage() {
  const { t, n } = useContext(TranslatorContext);

  const [isLoading, setIsLoading] = useState(false);
  const [dealId, setDealId] = useState("");

  const handleCreateMaster = async () => {
    setIsLoading(true);

    try {
      const result = await copyDealService(dealId);

      if (result?.data?.status) {
        toast?.success(result?.data?.message ?? "Trade copied successfully");
        setDealId("");
      } else {
        toast?.error(result?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast?.error("Something went wrong");
    }

    setIsLoading(false);
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Copy Deal")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">Home</li>
                <li className="mc-breadcrumb-item">Copy Deal</li>
              </ul>
            </div>
          </div>
        </Col>

        <Col xl={12}>
          <div className="mc-card">
            <Col xl={4} sm={12}>
              <LabelFieldComponent
                label={t("Enter the close deal")}
                type="number"
                fieldSize="mb-4 w-100 h-md"
                value={dealId}
                onChange={(e) => {
                  setDealId(e?.target?.value);
                }}
              />

              <ButtonComponent
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleCreateMaster();
                }}
                disabled={isLoading || !dealId.trim()?.length}
              >
                {isLoading ? <Spinner size="sm" /> : "Copy Deal to children"}
              </ButtonComponent>
            </Col>
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
