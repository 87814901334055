import React, { useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Link } from "react-router-dom"
import { Row, Col, Form } from "react-bootstrap"
import { ButtonComponent } from "../../components/elements"
import {
  LabelFieldComponent,
  LabelTextareaComponent,
} from "../../components/fields"
import PageLayout from "../../layouts/PageLayout"

export default function SettingsPage() {
  const { t } = useContext(TranslatorContext)

  return (
    <PageLayout>
      <div className="mc-card">
        <div className="mc-breadcrumb">
          <h3 className="mc-breadcrumb-title">{t("settings")}</h3>
          <ul className="mc-breadcrumb-list">
            <li className="mc-breadcrumb-item">
              <Link to="#" className="mc-breadcrumb-link">
                {t("home")}
              </Link>
            </li>
            <li className="mc-breadcrumb-item">{t("settings")}</li>
          </ul>
        </div>
      </div>
      <div className="mc-card p-sm-5">
        <div className="mb-5">
          <h6 className="mc-divide-title mb-4">{t("Settings")}</h6>
          <Row>
            <Col xl={4}>
              <p className="mc-setting-descrip">General Settings</p>
            </Col>
            <Col xl={8} className="ps-xl-5">
              <Row>
                <Col xl={12}>
                  <LabelFieldComponent
                    label={t("Attempts After Reject")}
                    type="text"
                    fieldSize="mb-4 w-100 h-md"
                    value={1}
                  />
                </Col>

                <Col xl={12}>
                  <LabelFieldComponent
                    label={t("Delay After Reject")}
                    type="text"
                    fieldSize="mb-4 w-100 h-md"
                    value={30}
                  />
                </Col>
                <Col xl={8} className="ps-xl-5">
                  <Form.Check
                    type="checkbox"
                    id="checkbox4"
                    className="mb-3"
                    label="Copy on startup"
                    defaultChecked
                  />
                  <Form.Check
                    type="checkbox"
                    id="checkbox1"
                    className="mb-3"
                    label="Log routes"
                    defaultChecked
                  />
                  <Form.Check
                    type="checkbox"
                    id="checkbox2"
                    className="mb-3"
                    label="Copy source price"
                  />
                  <Form.Check
                    type="checkbox"
                    id="checkbox2"
                    className="mb-3"
                    label="Copy sl tp"
                  />

                  <Form.Check
                    type="checkbox"
                    id="checkbox2"
                    className="mb-3"
                    label="Custom comment"
                  />

                  <Form.Check
                    type="checkbox"
                    id="checkbox2"
                    className="mb-3"
                    label="Copy magic number"
                  />

                  <Form.Check
                    type="checkbox"
                    id="checkbox2"
                    className="mb-3"
                    label="Round up to minimum volume"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* <div className="mb-5">
          <h6 className="mc-divide-title mb-4">{t("accessibility")}</h6>
          <Row>
            <Col xl={4}>
              <p className="mc-setting-descrip">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry dummy text ever since when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.
              </p>
            </Col>
          </Row>
        </div> */}
        <ButtonComponent
          className="mc-btn primary"
          icon="verified"
          text={t("save_all_changes")}
        />
      </div>
    </PageLayout>
  )
}
