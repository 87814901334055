import React, { useEffect, useState } from "react"
import { Modal, Spinner, ProgressBar } from "react-bootstrap"
import { useFormik } from "formik"
import { useDropzone } from "react-dropzone"
import * as Yup from "yup"
import { ButtonComponent } from "../../elements"
import api from "../../../services/api"
import { emitErrorNotification, emitNotification } from "../../../utils/helper"
import { useParams } from "react-router-dom"

const masterFormEditSchema = Yup.object().shape({
  file: Yup.mixed().required("CSV file is required"),
})

function UploadCsvModal({
  isUploadCsvModal,
  setIsUploadCsvModal,
  isRemovedSlavesModalOpen,
  setIsRemovedSlavesModalOpen,
}) {
  const { id } = useParams()
  const [uploading, setUploading] = useState(false)

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: masterFormEditSchema,
    onSubmit: async (values, actions) => {
      const formData = new FormData()
      formData.append("file", values.file)
      formData.append("masterID", id)
      if (isRemovedSlavesModalOpen) formData.append("type", "delete")

      setUploading(true)

      try {
        const response = await api.post("/slave/upload-csv", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        if (response?.status === 200) {
          if (response?.data?.message?.includes("failed")) {
            emitErrorNotification("There are no slaves to remove.")
            setIsUploadCsvModal(false)
          } else {
            emitNotification("success", response.data.message)
            setIsUploadCsvModal(false)
          }
        } else {
          emitErrorNotification("Upload failed with status:", response.status)
        }
      } catch (error) {
        emitErrorNotification("Upload error:", error)
      } finally {
        setUploading(false)
      }
    },
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: (acceptedFiles) => {
      formik.setFieldValue("file", acceptedFiles[0])
    },
  })

  useEffect(() => {
    if (!isUploadCsvModal) {
      formik.resetForm()
      setUploading(false)
      setIsRemovedSlavesModalOpen(false)
    }
  }, [isUploadCsvModal])

  return (
    <Modal
      centered
      show={isUploadCsvModal}
      onHide={() => setIsUploadCsvModal(false)}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="mc-alert-modal py-4">
          <h3 className="py-3">Upload CSV File</h3>
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed #ccc",
              padding: "20px",
              borderRadius: "4px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} />
            {formik.values.file ? (
              <p>{formik.values.file.name}</p>
            ) : (
              <p>Drag & drop a CSV file here, or click to select one</p>
            )}
          </div>
          {formik.errors.file && formik.touched.file && (
            <div className="text-danger mt-2">{formik.errors.file}</div>
          )}
          <br />

          <a
            href="/slaves.csv"
            style={{ textDecoration: "underline" }}
            target="_blank"
            download={true}
          >
            Download Demo file{" "}
          </a>
          <Modal.Footer>
            <ButtonComponent
              type="button"
              className="btn btn-secondary"
              onClick={() => setIsUploadCsvModal(false)}
            >
              Close
            </ButtonComponent>
            <ButtonComponent
              className={
                isRemovedSlavesModalOpen ? "btn btn-danger" : "btn btn-primary"
              }
              type="submit"
              disabled={uploading}
            >
              {uploading ? (
                <Spinner size="sm" />
              ) : isRemovedSlavesModalOpen ? (
                "Remove Bulk Slaves"
              ) : (
                "Upload Bulk Slaves"
              )}
            </ButtonComponent>
          </Modal.Footer>
        </div>
      </form>
    </Modal>
  )
}

export default UploadCsvModal
