import React, { useState, useEffect, useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import Modal from "react-bootstrap/Modal"
import { AnchorComponent, ButtonComponent } from "../elements"

export default function DealsTable({ thead, tbody }) {
  const { t } = useContext(TranslatorContext)

  const [alertModal, setAlertModal] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setData(tbody)
  }, [tbody])

  const handleCheckbox = (event) => {
    const { name, checked } = event.target

    if (name === "allCheck") {
      const checkData = data?.map((item) => {
        return { ...item, isChecked: checked }
      })
      setData(checkData)
    } else {
      const checkData = data?.map((item) =>
        item.name === name ? { ...item, isChecked: checked } : item
      )
      setData(checkData)
    }
  }

  return (
    <div className="mc-table-responsive">
      <table className="mc-table product">
        <thead className="mc-table-head primary">
          <tr>
            {thead.map((item, index) => (
              <th key={index}>{t(item)}</th>
            ))}
          </tr>
        </thead>
        <tbody className="mc-table-body even">
          {!data?.length ? (
            <tr>
              <td colSpan={thead?.length} style={{ textAlign: "center" }}>
                No data found
              </td>
            </tr>
          ) : (
            <></>
          )}

          {data?.map((item, index) => (
            <tr key={item?.id}>
              <td>{item.Deal}</td>
              <td>{item.Login}</td>
              <td>{item.Volume}</td>
              <td>{item.Price}</td>
              <td>{item.Symbol}</td>
              <td>{item.Action === 0 ? "BUY" : "SELL"}</td>
              <td>{item.Entry === 0 ? "IN" : "OUT"}</td>
              <td>{item.Order}</td>
              <td>{item.Profit}</td>
              <td>{item.TimeMsc ?? "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <div className="mc-alert-modal">
          <i className="material-icons">new_releases</i>
          <h3>are your sure!</h3>
          <p>Want to delete this mapping?</p>
          <Modal.Footer>
            <ButtonComponent
              type="button"
              className="btn btn-secondary"
              onClick={() => setAlertModal(false)}
            >
              {t("close")}
            </ButtonComponent>
            <ButtonComponent
              type="button"
              className="btn btn-danger"
              onClick={() => setAlertModal(false)}
            >
              {t("delete")}
            </ButtonComponent>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  )
}
