import Cookies from "js-cookie";
import { COOKIES_CONSTANT } from "../constant";

/**
 * Stores the token and its expiration time in a cookie.
 * @param {string} token - The token to store.
 * @param {number} expiryInHours - The expiration time in hours.
 */
export const storeToken = (token, expiryInHours = 12) => {
  const expiryDate = new Date(
    new Date().getTime() + expiryInHours * 60 * 60 * 1000
  );
  Cookies.set(COOKIES_CONSTANT.TOKEN, token, { expires: expiryDate });
};

/**
 * Retrieves the token from cookies.
 * @returns {string|null} The token if present, otherwise null.
 */
export const getToken = () => {
  return Cookies.get(COOKIES_CONSTANT.TOKEN) || null;
};

/**
 * Checks if the token is still valid and returns it if so.
 * @returns {string|null} The token if valid, otherwise null.
 */
export const checkTokenExpiry = () => {
  const token = Cookies.get(COOKIES_CONSTANT.TOKEN);
  if (token) {
    // Assuming the token contains expiration information or using some logic to verify its validity
    return token; // Token is valid if present
  } else {
    // Token is not present or expired
    return null;
  }
};

/**
 * Removes the token and user type from cookies.
 */
export const removeToken = () => {
  Cookies.remove(COOKIES_CONSTANT.TOKEN);
};
