import React, { useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import {
  EcommerceCardComponent,
  SalesCardComponent,
  ProductsCardComponent,
  RevenueCardComponent,
  ClientsCardComponent,
  ActivityCardComponent,
  OrdersCardComponent,
  CardHeaderComponent,
} from "../../components/cards"
import PageLayout from "../../layouts/PageLayout"
import heros from "../../assets/data/heros.json"
import DealsTable from "../../components/tables/DealsTable"
import { getDashboardService } from "../../services/main"
import { Loader } from "../../context/Loader"
import { useQuery } from "@tanstack/react-query"

const d = {
  thead: [
    "Deal ID",
    "Login",
    "volume",
    "price",
    "Symbol",
    "action",
    "entry",
    "Order ID",
    "profit",
    "time",
  ],
  tbody: [
    {
      positon: "195290",
      order: "249315",
      deal: "198720",
      login: "10023",
      symbol: "EURUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      action: "BUY",
      entry: "IN",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      positon: "195290",
      order: "249315",
      deal: "198720",
      login: "10023",
      symbol: "EURUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      actionVal: "SELL",
      entry: "OUT",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      positon: "295290",
      order: "349315",
      deal: "298720",
      login: "10026",
      symbol: "GBPUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      actionVal: "SELL",
      entry: "IN",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      positon: "195290",
      order: "249315",
      deal: "198720",
      login: "10023",
      symbol: "EURUSD",
      volume: "50000",
      price: "1.06138",
      sl: "1.05999",
      tp: "0",
      profit: "100",
      action: "BUY",
      entry: "IN",
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
  ],
}

export default function DashboardPage() {
  const { t, n } = useContext(TranslatorContext)

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["dashboard"],
    queryFn: getDashboardService,
  })

  if (isLoading)
    return (
      <>
        <Loader />
      </>
    )
  const statistics = data?.data?.data?.statistics ?? {}
  const last5Deals = data?.data?.data?.last_5_deals ?? []
  const topManagers = data?.data?.data?.top_5_money_manager ?? []

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Dashboard")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">Home</li>
                <li className="mc-breadcrumb-item">Dashboard</li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={12}>
          <Row xs={1} sm={4}>
            <Col>
              <EcommerceCardComponent
                icon="account_circle"
                trend="trending_up"
                title="total Investors"
                variant="green"
                number={statistics?.investors ?? 0}
              />
            </Col>
            <Col>
              <EcommerceCardComponent
                icon="account_circle"
                trend="trending_up"
                title="total money managers"
                variant="purple"
                number={statistics?.money_managers ?? 0}
              />
            </Col>
            <Col>
              <EcommerceCardComponent
                icon="account_circle"
                trend="trending_up"
                title="total deals"
                variant="blue"
                number={statistics?.deals ?? 0}
              />
            </Col>

            <Col>
              <EcommerceCardComponent
                icon="account_circle"
                trend="trending_up"
                title="total profits"
                variant="yellow"
                number={statistics?.profit ?? 0}
              />
            </Col>
          </Row>
        </Col>

        <Col xl={8}>
          <div className="mc-card">
            <CardHeaderComponent title="Last 5 Deals" />
            <DealsTable thead={d.thead} tbody={last5Deals} />
          </div>
        </Col>
        <Col xl={4}>
          <OrdersCardComponent managers={topManagers} />{" "}
        </Col>
      </Row>
    </PageLayout>
  )
}
