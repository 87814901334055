import React, { useContext, useState } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Row, Col, Spinner } from "react-bootstrap"
import { BreadcrumbComponent, PaginationComponent } from "../../components"
import PageLayout from "../../layouts/PageLayout"
import RulesTable from "../../components/tables/RulesTable"
import {
  createMasterService,
  deleteMasterService,
  getMastersService,
} from "../../services/main"
import { Loader } from "../../context/Loader"
import { useQuery } from "@tanstack/react-query"
import Modal from "react-bootstrap/Modal"
import { ButtonComponent } from "../../components/elements"
import { LabelFieldComponent } from "../../components/fields"
import toast, { Toaster } from "react-hot-toast"
import { useNavigate } from "react-router-dom"

const d = {
  thead: ["id", "master", "slave", "status", "action"],
  tbody: [
    {
      name: "Test 1",
      master: "1123",
      slave: "1124, 1125,1126",
      groups: "demo",
      nmask: "*",
      translation: "A to B",
      fill: "-",
      inverse: true,
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      name: "Test 2",
      master: "1123",
      slave: "1124, 1125,1126",
      groups: "demo",
      nmask: "*",
      translation: "A to B",
      fill: "-",
      inverse: true,
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
    {
      name: "Test 3",
      master: "2123",
      slave: "1124, 1125,1126",
      groups: "demo",
      nmask: "*",
      translation: "A to B",
      fill: "-",
      inverse: true,
      action: { edit: "edit", delete: "delete", view: "visibility" },
    },
  ],
}

export default function RulesPage() {
  const { t } = useContext(TranslatorContext)

  const [currentPage, setCurrentPage] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const [deleteMaster, setDeleteMaster] = useState(undefined)

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["masters"],
    queryFn: () => getMastersService(currentPage),
  })

  const masters = data?.data?.data?.masters ?? []

  if (isLoading) <Loader />

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <BreadcrumbComponent title={"Rules"}>
            <li className="mc-breadcrumb-item">Home</li>
            <li className="mc-breadcrumb-item">Rules</li>
          </BreadcrumbComponent>
        </Col>
        <Col xl={12}>
          <div className="mc-card">
            {/* <CardHeaderComponent
              title={"Symbol Mappings"}
              dotsMenu={data?.products.dotsMenu}
            /> */}
            <div style={{ textAlign: "right", width: "100%" }}>
              <button
                onClick={() => {
                  setIsOpen(true)
                }}
                className="mc-btn primary sm"
              >
                Create
              </button>
            </div>
            <br />
            <RulesTable
              thead={d.thead}
              tbody={masters}
              setDeleteMaster={setDeleteMaster}
            />

            {/* <PaginationComponent /> */}
            <CreateDealModal alertModal={isOpen} setAlertModal={setIsOpen} />
            <MasterDeleteModal
              deleteMaster={deleteMaster}
              setDeleteMaster={setDeleteMaster}
              refetch={refetch}
            />
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}

const MasterDeleteModal = ({ deleteMaster, setDeleteMaster, refetch }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDeleteRule = async () => {
    setIsDeleting(true)

    try {
      const result = await deleteMasterService(deleteMaster?.id)
      console.log(result)
      if (result?.data?.status) {
        await refetch()
        toast?.success("Rule deleted successfully")
        setDeleteMaster(undefined)
      } else {
        toast?.error("Error in deleting rule")
      }
    } catch (error) {
      console.log(error)
    }

    setIsDeleting(false)
  }

  return (
    <>
      <Modal
        centered
        show={deleteMaster ? true : false}
        onHide={() => setDeleteMaster(false)}
      >
        <div className="mc-alert-modal">
          <i className="material-icons">new_releases</i>
          <h3>are your sure!</h3>
          <p>Want to delete this rule?</p>
          <Modal.Footer>
            <ButtonComponent
              type="button"
              className="btn btn-secondary"
              onClick={() => setDeleteMaster(false)}
              disabled={isDeleting}
            >
              close
            </ButtonComponent>
            <ButtonComponent
              type="button"
              className="btn btn-danger"
              onClick={() => {
                handleDeleteRule()
              }}
              disabled={isDeleting}
              size="sm"
            >
              {isDeleting ? <Spinner size="sm" /> : "Delete"}
            </ButtonComponent>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

const CreateDealModal = ({ alertModal, setAlertModal, refetch }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [login, setLogin] = useState("")
  const navigate = useNavigate()

  const handleCreateMaster = async () => {
    setIsLoading(true)
    try {
      const result = await createMasterService(login)
      if (result?.data?.status) {
        console.log(result?.data)
        toast.success("Master created successfully")
        setLogin("")
        navigate(`/rules/edit/${result?.data?.data?.master?.login}`)
        // route to        result?.data?.master?.id
      } else {
        toast?.error(result?.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Modal centered show={alertModal} onHide={() => setAlertModal(false)}>
        <div className="mc-alert-modal">
          {/* <i className="material-icons">new_releases</i> */}
          <br />
          <br />
          <h3>Create Rule!</h3>
          <br />

          <LabelFieldComponent
            style={{ width: "100%" }}
            label="Login"
            type="number"
            placeholder="Enter Master Login ID"
            value={login}
            onChange={(e) => {
              setLogin(e?.target?.value)
            }}
          />

          <Modal.Footer>
            <ButtonComponent
              type="button"
              className="btn btn-secondary"
              onClick={() => setAlertModal(false)}
              disabled={isLoading}
            >
              close
            </ButtonComponent>
            <ButtonComponent
              type="button"
              className="btn btn-danger"
              onClick={() => {
                handleCreateMaster()
              }}
              disabled={isLoading || !login?.length}
            >
              {isLoading ? <Spinner size="sm" /> : "Create"}
            </ButtonComponent>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}
